<template>
  <div class="input_key_word">
    <div v-for="(item,index) in all.list" :key="index">
      <div
        v-show="all.isShow"
        class="li"
        @click="all.btnList(item.suggestions.suggestion)"
      >
        <span>{{ item.suggestions.suggestion }}</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'InputKeyWord',
  // props:["inputLIst"],
  props: {
    inputLIst: {
      type: Object,
      default: () => {
        return {
          list: '', // 接口 数据
          btnList: () => {
          }, // 点击list
          isShow: false
        }
      }
    }
  },

  data() {
    return {
      list: ''
    }
  },
  computed: {
    all() {
      const Obj = this.inputLIst
      return Obj
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang="less">
.input_key_word {
  width: 100%;
  position: absolute;
  background: #fff;
  z-index: 1;
  margin-top: 50px;

  .li {
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    text-align: left;
    padding-left: 12px;
    box-sizing: border-box;
  }
}

</style>
