<template>
  <div class="search-result">
    <!--        <div class="search-box">-->
    <!--            <img class="search_go" @click.prevent="backLastPage('./')" src="../../assets/images/MoreIocn.png" alt="">-->
    <!--            <form action="/">-->
    <!--                <van-search :left-icon="require('../../assets/images/search.png')" v-model="searchValue" shape="round" clearable placeholder="请输入商品名称" @search="goodsSearch"/>-->
    <!--            </form>-->
    <!--            <div class="cancelButton" @click.prevent="backLastPage('./')">取消</div>-->
    <!--        </div>-->
    <!--搜索 联动搜索-->
    <div class="search-top">
      <headSearch :search-out="headSearch" />
      <inputKeyWord :input-l-ist="inputLIst" />
    </div>
    <!--tab -->
    <div class="menu_tab">
      <van-tabs v-model="active">
        <van-tab v-for="(item,index) in menu" :key="index">
          <template #title>
            <div @click="changeSearchMenu(item,index)">
              <div class="">{{ item.title }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
    <!--视频跳转-->
    <a :href="historyVideo">
      <img class="search_go_img" :src="historyImg" alt="">
    </a>
    <div class="searchListBox">
      <div v-show="searchHistoryList.length >=1" class="status-box">
        <div class="tits">
          <span>搜索历史</span>
          <div class="clearBox" @click="clearHistoryKey">
            <img class="icon-delete" src="../../assets/images/delete.png">
            <span class="clearName">清空</span>
          </div>
        </div>
        <div class="status">
          <span v-for="(val,i) in searchHistoryList.slice(0,10)" :key="i" class="box box_son" @click="goodsSearch(val)">{{ val }}</span>
        </div>
      </div>

      <div v-for="(item,index) in historyData" :key="index" class="status-box">
        <div class="tits">{{ item.categoryName }}</div>
        <div class="status">
          <span v-for="(val,i) in item.keyChild" :key="i" class="box">
            <img v-if="val.img" class="icon-hot" :src="val.img" alt="">
            <span @click="goodsSearch(val,1)">{{ val.keyword }}</span>
          </span>
        </div>
      </div>
    </div>

    <!--清除 提示 -->
    <div v-if="isShow" class="warning_box">
      <div class="warning_son">
        <div> 是否清除历史记录？</div>
        <div class="warning_btn">
          <div @click="isShow=false">取消</div>
          <div @click="btnWarning()">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import headSearch from '@/component/head/search'
import inputKeyWord from '@/component/head/inputKeyWord'
import { Cell, Lazyload, List, Search, Swipe, SwipeItem, Tab, Tabs, Toast } from 'vant'
Vue.use(Tab).use(Tabs).use(Toast).use(List).use(Cell).use(Search).use(Swipe).use(SwipeItem).use(Lazyload)
import { goodsSearchHistory, suggestion, getChannelSearchWord } from '@/services/api'

export default {
  components: {
    headSearch,
    inputKeyWord
  },
  data() {
    return {
      active: '',
      headSearch: {
        boxHeight: '42px',
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        searchWidth: '76%',
        bgColor: '#f8f8f8', // 背景颜色
        placeholder: ' ',
        callbackGo: this.callbackGo, // 点击返回 上一页 回调
        callbackDown: this.callbackInputGo, // 搜索 路由页面
        btnRefresh: true,
        callbackRefresh: this.callbackRefresh,
        isSearch: '', // input val
        callbackOnInput: this.callbackOnInput //
      },
      inputLIst: {
        list: '', // 搜索关键字list
        btnList: this.btnList, // 回调方法
        isShow: false
      },
      // inputLIst:"",  // list
      searchValue: '',
      historyData: [], // 搜索页数据
      searchHistoryList: [],
      historyImg: '',
      historyVideo: '',
      menu: '',
      isShow: false // 删除提升

    }
  },
  created() {
    const { channelId, searchWord } = this.$route.query
    this.headSearch.channelId = Number(channelId) || ''
    this.headSearch.placeholder = searchWord || ' '
    if (!channelId) {
      this.getChannelSearchWord()
    }
    const newKeyList = JSON.parse(window.localStorage.getItem('searchHistoryKey'))
    this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : []
  },
  mounted() {
    this.searchHistory()
  },
  methods: {
    // 输入 回调 请求 搜索接口
    callbackOnInput: function() {
      const data = {
        keyword: this.headSearch.isSearch
      }
      if (data.keyword.trim()) {
        suggestion(data).then((res) => {
          if (res.code === 200) {
            this.inputLIst.list = res.data
            this.inputLIst.isShow = true
          }
        })
      }
    },
    // 点击回调获取 赋值
    btnList: function(data) {
      this.headSearch.isSearch = data
      this.inputLIst.isShow = false

      if (data.trim()) {
        if (this.searchHistoryList.indexOf(data) < 0) {
          this.searchHistoryList.unshift(data)
          const localObj = JSON.stringify(this.searchHistoryList)
          window.localStorage.setItem('searchHistoryKey', localObj)
        }
        this.$router.push({
          path: '/goodsList',
          query: {
            searchKeyword: data,
            channelId: this.headSearch.channelId || '',
            fromPage: 'goodsSearch'
          }
        })
      }
    },

    callbackGo: function() {
      this.$router.go(-1)
    },
    callbackRefresh: function() {
      this.$router.go(-1)
    },
    callbackInputGo: function(val) {
      let newVal = ''
      if (val.trim()) {
        newVal = val
      } else {
        newVal = this.headSearch.placeholder
        let setIndex = 0

        this.menu.forEach((e, index) => {
          if (e.channelid === this.headSearch.channelId) {
            setIndex = index
          }
        })

        this.$store.commit('btnTabVant', setIndex)
        this.$store.commit('btnTabVal', this.headSearch.channelId)
      }
      if (this.searchHistoryList.indexOf(val) < 0) {
        this.searchHistoryList.unshift(val)
        const localObj = JSON.stringify(this.searchHistoryList)
        window.localStorage.setItem('searchHistoryKey', localObj)
      }
      this.$router.push({
        path: '/goodsList',
        query: {
          searchKeyword: newVal,
          channelId: this.headSearch.channelId || '',
          fromPage: 'goodsSearch'
        }
      })
    },

    // tab切换【仅当首页过来的搜索】
    changeSearchMenu(item, index) {
      this.$store.commit('btnTabVant', index)
      this.$store.commit('btnTabVal', item.channelid)
    },
    backLastPage(val) {
      val === -1 ? this.$router.back(-1) : this.$router.push(val)
    },
    goodsSearch(val, t) {
      // localStorage.setItem("searchHistory", val);//搜索历史记录
      // ['太阳镜', '智能手环', '智能手机', '无人机', '电视', '清洁工具', '苹果', '水果', '西瓜', '自行车']
      if (t == 1) {
        if (val.redirectInfo.redirectType === 3) {
          const obj = {
            product_type: 1,
            goodId: val.redirectInfo.id,
            skuId: val.redirectInfo.skuId
          }
          this.$store.dispatch('godetail', obj)
        } else if (val.redirectInfo.redirectType === 29 || val.redirectInfo.redirectType === 111 || val.redirectInfo.redirectType === 246) {
          window.location.href = val.redirectInfo.redirectParams.redirectUrl
        } else if (val.redirectInfo.redirectType === 39) {
          this.$router.push({ path: '/acthds', query: { id: val.redirectInfo.id }})
        }
      } else {
        if (val.trim()) {
          if (this.searchHistoryList.indexOf(val) < 0) {
            this.searchHistoryList.unshift(val)
            const localObj = JSON.stringify(this.searchHistoryList)
            window.localStorage.setItem('searchHistoryKey', localObj)
          }
          this.$router.push({
            path: '/goodsList',
            query: {
              searchKeyword: val,
              channelId: this.headSearch.channelId || '',
              fromPage: 'goodsSearch'
            }
          })
        }
      }
    },
    // 清除搜索历史记录
    clearHistoryKey() {
      // this.searchHistoryList = [];
      // window.localStorage.removeItem('searchHistoryKey');
      this.isShow = true
    },
    // 清除提示
    btnWarning: function() {
      this.searchHistoryList = []
      window.localStorage.removeItem('searchHistoryKey')
      this.isShow = false
    },
    // 获取搜索历史
    searchHistory() {
      goodsSearchHistory().then(res => {
        this.historyData = res.data.data
        this.historyImg = res.data.img
        this.historyVideo = res.data.newVideo

        this.menu = res.data.menu
        this.active = this.menu.findIndex(i => i.channelid === this.headSearch.channelId) || 0
      })
    },
    // 请求当前搜索词
    getChannelSearchWord() {
      const prom = {}
      getChannelSearchWord(prom).then(res => {
        if (res.code === 200) {
          this.headSearch.channelId = res.data.channelId
          this.headSearch.placeholder = res.data.searchWord
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

    .search_go{
      transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      position: absolute;
      left: 16px;
      top: 18px;
      width: 11px;
    }
    .search_go_img{
      padding: 12px;
    }
    .searchListBox{
        background: #fff;
        padding: 12px 14px;
        height: 100%;
    }
    .search-result {
      .search-top{
        /deep/ .search_input{
          transform: translate(-53% ,-50%);
        }
      }
        width: 100%;
        height: 100vh;
        //height: calc(100vh - 120px);
        box-sizing: border-box;
        .search-box {
            width: 100%;
            height: 46px;
            padding: 10px;
            margin-bottom: 12px;
            position: relative;
            .menu_tab{
              background: #f6f6f6;
            }
            .cancelButton {
                height: 45px;
                line-height: 35px;
                float: right;
                margin-top: 2px;
            }
            .van-search {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border: 1px solid rgba(235,116,118,1);
                width: 280px;
                padding: 0;
                background-color: #f0f2f5;
                border-radius:20px;
                margin-left: -10px;
            }
        }
        .status-box {
            width: 100%;
            margin-bottom:30px;
            .tits {
                font-size: 14px;
                font-weight: 500;
                color: #141f33;
                margin-bottom: 12px;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                .clearBox {
                    width: 45px;
                    height: 18px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    .icon-delete {
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        right: 27px;
                        top: 0;
                    }
                    .clearName {
                        position: absolute;
                        right: 0;
                        top: 3px;
                        font-size: 13px;
                        color: rgb(186, 186, 186);
                    }
                }

            }
            .status {
                width: 100%;
                overflow: hidden;
                .box {
                    width: auto;
                    padding: 4px 16px;
                    background: #F2F2F2;
                    font-size: 12px;
                    color: #141414;
                    //display: inline-block;
                    margin-right: 12px;
                    margin-bottom: 12px;
                    float: left;
                    border-radius: 14px;
                    display: flex;
                    align-items: center;
                    .icon-hot {
                        width: 12px;
                        height: 14px;
                    }

                }
              .box_son{
                max-width: 110px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

            }
        }
      /deep/ .van-tab--active {
        color: red;
      }
      /deep/ .van-tabs__line {
        background: #f6f6f6;
      }
      .warning_box{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(0,0,0,0.4);
        .warning_son{
          width: 200px;
          min-height: 10px;
          padding: 14px 10px 12px 10px;
          box-sizing: border-box;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50% ,-50%);
          background: #fff;
          border-radius: 4px;
          .warning_btn{
            display: flex;
            width: 80%;
            margin: 28px auto 0;
            div{
              flex: 1;
            }
          }
        }
      }

    }
</style>
